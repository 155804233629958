import React from 'react'
import { MdOutlineNoLuggage } from "react-icons/md";
import { LuBookOpenCheck } from "react-icons/lu";
import { RiServiceFill } from "react-icons/ri";
import { CiSquareCheck } from "react-icons/ci";
import { GiPathDistance } from "react-icons/gi";
import bgslider from '../../assets/images/bg_slide.jpg'
const Chooseus = () => {
    const divStyle = {
        backgroundImage: `url(${bgslider})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    
      };
    return (
        <div class='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
            
            <div className='text-center font-bold text-3xl mt-3 mb-6'>Why Choose Us</div>
            <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4  gap-3 mt-6'>
                <div className='shadow-2xl  rounded-lg flex flex-col text-white gap-3 justify-center items-center px-3 py-5 text-center' style={divStyle}>
                    <div className='text-white text-2xl'>Affordable Pricing</div>
                  
                        <LuBookOpenCheck className='text-white' size={100} />
                        <span className='text-center'>  Enjoy competitive rates without compromising on comfort and safety. </span>
                 
                </div>
                <div className='shadow-2xl bg-[black] rounded-lg flex flex-col text-white gap-3 justify-center items-center px-3 py-5 text-center' style={divStyle}>
                    <div className='text-white text-2xl'>Safety First</div>
                  
                    <RiServiceFill  className='text-white' size={100} />
                        <span className='text-center'> Your safety is our top priority. Our drivers are well-trained, and our vehicles are regularly maintained to ensure a secure journey.  </span>
                 
                </div>
                <div className='shadow-2xl bg-[black] rounded-lg flex flex-col text-white gap-3 justify-center items-center px-3 py-5 text-center' style={divStyle}>
                    <div className='text-white text-2xl'>Convenient Travel</div>
                  
                    <CiSquareCheck  className='text-white' size={100} />
                        <span className='text-center'> With hassle-free bookings and timely service, we guarantee a stress-free travel experience.  </span>
                 
                </div>
                <div className='shadow-2xl bg-[black] rounded-lg flex flex-col text-white gap-3 justify-center items-center px-3 py-5 text-center ' style={divStyle}>
                    <div className='text-white text-2xl'>All Major Destinations</div>
                  
                    <GiPathDistance  className='text-white' size={100} />
                        <span className='text-center'>We cover all major cities in Tamil Nadu, providing convenient point-to-point drop services.  </span>
                 
                </div>
            </div>

        </div>
    )
}

export default Chooseus