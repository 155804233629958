import React from 'react'
import about from "../assets/images/about-logo.jpg";
import { FaPhone } from 'react-icons/fa6';
import about_bg from "../assets/about_bg.jpg";
const About = () => {

    return (
        <div id='about' className='bg-black '>
            <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
                <div className='block md:flex py-5 gap-x-20 items-center'>
                    <div className='w-full md:w-1/2 '>
                        <div className=' relative'>
                            <img src={about} alt="about" className='mx-auto w-[500px] ' />
                        </div>
                    </div>
                    <div className='mt-10 md:mt-0 px-3 w-full md:w-1/2'>
                        <h4 className=' text-lg font-medium primary-color '>About Us</h4>
                        <div className='text-3xl md:text-4xl font-bold mt-4 text-white'>Welcome to Elite Drop Taxi </div><p className='text-white opacity-7 mt-2'> Your Trusted Travel Partner</p>
                        <p className='text-black opacity-60 md:text-lg mt-5 text-white'>At Elite Drop Taxi, we specialize in providing reliable and affordable drop taxi services across Tamil Nadu and Bangalore. Whether you're traveling from Chennai to any major city in Tamil Nadu or planning a long-distance journey, we ensure a smooth, safe, and comfortable experience for every trip.</p>
                        <div className=' mt-5'>
                            <a
                                href="tel:+919790001775"
                                className=" rounded-md th-btn hover:bg-black transition-all ease-linear px-3 py-2"  >
                                    
                                <span className=" flex gap-x-2 items-center justify-center">
                                    <FaPhone size={17} /> +91 9790001775
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About